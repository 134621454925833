/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { graphql } from "gatsby";

import { AppContext } from "~context/AppContext";

import Banner from "~components/Banner";
import KlaviyoForm from "~components/KlaviyoForm";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

class NewsletterPageComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;

    appContext.setMenuActive(false);
    appContext.setNavText(`Back`);
  }

  //

  render() {
    const { bannerMedia } = this.props.data.markdownRemark.frontmatter;

    return (
      <>
        <SEO pageTitle="Newsletter" pathName="/newsletter" />

        <Layout className="newsletter-page w-full bg-sun-yellow gpu">
          {bannerMedia && bannerMedia.src && bannerMedia.bannerText && (
            <Banner
              bannerImage={bannerMedia.src.childImageSharp.fluid}
              bannerText={bannerMedia.bannerText}
            />
          )}
          <section className="grid">
            <article className="grid-end-10 xs:grid-end-11">
              <h2 className="animation-appear newsletter-page__title f1 font-medium">
                Is your inbox ugly?
              </h2>
            </article>

            <article
              className="animation-appear grid-end-8 xs:grid-end-11"
              style={{ animationDelay: `50ms` }}
            >
              <h1 className="newsletter-page__title mt-2 f1 font-medium">
                Sign up and we’ll make it pretty.
              </h1>
            </article>
          </section>

          <KlaviyoForm className="pt-4 pb-32" klaviyoId="Kqkz9T">
            <p
              className="makeunder-page__intro animation-appear grid-end-8 xs:grid-end-11 mb-24 xs:mb-12 f3"
              style={{ animationDelay: `100ms` }}
            >
              We’ll talk about our issues, tell you when we have events, and
              maybe send you free Fluff &amp; Stuff.
            </p>
          </KlaviyoForm>
        </Layout>
      </>
    );
  }
}

const NewsletterPage = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <NewsletterPageComponent appContext={appContext} {...props} />
      )}
    </AppContext.Consumer>
  );
};

export default NewsletterPage;

export const query = graphql`
  query {
    markdownRemark {
      frontmatter {
        bannerMedia {
          bannerText
          src {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                src
              }
            }
          }
        }
      }
    }
  }
`;
